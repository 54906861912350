import { muiButton } from './MuiButton';
import { muiFilledInput } from './MuiFilledInput';
import { muiFormHelperText } from './MuiFormHelperText';
import { muiPaper } from './MuiPaper';
import { Components } from '@mui/material/styles';

export const components: Components = {
  MuiButton: { styleOverrides: muiButton },
  MuiFilledInput: { styleOverrides: muiFilledInput },
  MuiFormHelperText: { styleOverrides: muiFormHelperText },
  MuiPaper: { styleOverrides: muiPaper },
  MuiTextField: {
    defaultProps: {
      variant: 'standard',
    },
  },
};
