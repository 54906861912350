import styled from 'MUI/theme/styled';

export const Partners = styled('div')(
  ({
    theme: {
      palette: { white },
    },
  }) => ({
    padding: '50px 0 ',
    bottom: 0,
    boxSizing: 'border-box',
    width: '100%',
    maxWidth: '1100px',
    margin: 'auto',
    backgroundColor: white,
  })
);

export const PartnersHeader = styled('h2')(
  ({
    theme: {
      palette: { black },
      typography: { fontFamily },
    },
  }) => ({
    margin: '0 auto 32px',
    marginBottom: '12px',
    textAlign: 'center',
    fontSize: '24px',
    fontWeight: 'bold',
    fontFamily,
    color: black,
  })
);

export const PartnersContainer = styled('div')(
  ({
    theme: {
      palette: { white },
    },
  }) => ({
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    gap: '16px',
    flexWrap: 'wrap',
    margin: '10px 0 0',
    backgroundColor: white,
  })
);

export const StyledAnchor = styled('a')({
  textDecoration: 'none',
  cursor: 'pointer',
  display: 'flex',
  flexDirection: 'column',
  padding: '4px',
  '@media(min-width: 500px)': {
    padding: '4px',
  },
});

export const LogoLabel = styled('label')(
  ({
    theme: {
      typography: { body1 },
    },
  }) => ({
    width: '100%',
    textAlign: 'center',
    display: 'block',
    ...body1,
    fontSize: '24px',
  })
);

export const PartnerDescription = styled('p')(
  ({
    theme: {
      typography: { fontFamily },
    },
  }) => ({
    margin: '0',
    lineHeight: '150%',
    textAlign: 'center',
    fontFamily,
    color: '＃333333',
  })
);
