import styled from 'MUI/theme/styled';
import { Link } from 'gatsby';

export const Wrapper = styled('header')(({ theme }) => ({
  zIndex: 1000,
  justifyContent: 'center',
  backgroundColor: theme.palette.primary.dark,
  display: 'flex',
  width: '100%',

}));

export const Container = styled('div')({
  height: '100%',
  maxWidth: '1280px',
  width: '100%',
  display: 'flex',
  alignItems: 'center',
  padding: '0 84px',
  boxSizing: 'border-box',
  '@media(max-width: 500px)': {
    padding: '0 48px',
    flexWrap: 'wrap',
  },
});

export const LogoLink = styled(Link)({
  display: 'flex',
  padding: '0',
  gap: '54px',
  '@media(max-width: 624px)': {
    margin: 'auto',
  },
});

export const Heading = styled('p')(
  ({
    theme: {
      palette: { white },
    },
  }) => ({
    color: white,
    fontSize: '22px',
    marginLeft: '50px',
    '@media(min-width: 800px)': {
      fontSize: '28px',
    },
  })
);
