import { StaticImage } from 'gatsby-plugin-image';
import * as S from './BannerLaunchpad.style';

export default function BannerLaunchpad() {
  return (
    <S.Wrapper>
      <S.Header>Ecosystem Launchpad</S.Header>
      <StaticImage
        width={182}
        objectFit={'contain'}
        src={'../../Images/Ecosystem_Launchpad_White.svg'}
        alt={'Amazon Web Services'}
        layout="constrained"
      />
    </S.Wrapper>
  );
}
