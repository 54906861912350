import { ReactNode } from 'react';
import TopBar from 'Components/TopBar';
import ToSModal from 'Components/ToSModal/ToSModal';
import AboutModal from 'Components/AboutModal';
import Partners from 'Components/Partners';
import * as S from './Layout.style';
import BannerLaunchpad from 'Components/BannerLaunchpad/BannerLaunchpad';

export default function Layout({ children }: { children: ReactNode }) {
  return (
    <S.Wrapper>
      <ToSModal />
      <AboutModal />
      <TopBar />
      <BannerLaunchpad />
      {children}
      <Partners />
    </S.Wrapper>
  );
}
