import styled from 'MUI/theme/styled';
import backgroundDesktop from 'Images/gradient-bkg.png';
import backgroundMobile from 'Images/gradient-bkg-mobile.png';

export const Wrapper = styled('section')(() => ({
  zIndex: 1000,
  justifyContent: 'center',
  backgroundImage: `url(${backgroundMobile})`,
  backgroundPosition: 'center',
  backgroundSize: 'cover',
  display: 'flex',
  flexWrap: 'wrap',
  alignItems: 'center',
  width: '100%',
  minHeight: '260px',
  gap: '24px 56px',
  boxSizing: 'border-box',
  padding: '36px 42px',
  '@media(min-width: 800px)': {
    padding: '36px 84px',
    backgroundImage: `url(${backgroundDesktop})`,
  },
}));

export const Header = styled('h1')(
  ({
    theme: {
      palette: { white },
    },
  }) => ({
    color: white,
    fontSize: '22px',
    margin: 0,
    '@media(min-width: 800px)': {
      fontSize: '56px',
    },
  })
);
