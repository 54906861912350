import React from 'react';
import Layout from './src/Components/Layout';
import { ThemeProvider } from '@mui/material';
import { ContextProvider } from './src/Components/Context';
import awsTheme from './src/MUI/theme/awsTheme';
import './global.css';

export const wrapRootElement = ({ element }) => (
  <ThemeProvider theme={awsTheme}>
    <ContextProvider>
      <Layout>{element}</Layout>
    </ContextProvider>
  </ThemeProvider>
);
