import { ClickAwayListener } from '@mui/material';
import Context, { MODAL_TYPE } from 'Components/Context';
import { useContext } from 'react';
import * as S from './AboutModal.style';

export default function AboutModal() {
  const { selectedModal, setSelectedModal } = useContext(Context);
  if (!selectedModal || selectedModal.modalType !== MODAL_TYPE.ABOUT) {
    return null;
  }
  const {
    workflowData: { title, modalContent },
  } = selectedModal;
  const handleClose = () => setSelectedModal(undefined);
  return (
    <S.Wrapper>
      <ClickAwayListener onClickAway={handleClose}>
        <S.ModalWrapper>
          <S.ModalHeader>{title}</S.ModalHeader>
          <S.DescriptionContainer dangerouslySetInnerHTML={{ __html: modalContent }} />
          <S.AcceptToS onClick={handleClose}>Close</S.AcceptToS>
        </S.ModalWrapper>
      </ClickAwayListener>
    </S.Wrapper>
  );
}
