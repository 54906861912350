import { CircularProgress, ClickAwayListener } from '@mui/material';
import { useContext, useState } from 'react';
import Context, { MODAL_TYPE } from 'Components/Context/Context';
import * as S from './ToSModal.style';

const subscribeConsoleUrl = process.env.GATSBY_EDI_SERVICE_CONSOLE_URL || '';

export default function ToSModal() {
  const { selectedModal, setSelectedModal } = useContext(Context);

  if (!selectedModal || selectedModal.modalType !== MODAL_TYPE.TOS) {
    return null;
  }

  const {
    workflowData: { workflowType, title },
  } = selectedModal;

  const [isLoading, setIsLoading] = useState(false);

  const handleClose = () => !isLoading && setSelectedModal(undefined);

  function handleAccept(): void {
    if (!workflowType) {
      return;
    }
    setIsLoading(true);
    window.location.href = `${subscribeConsoleUrl}/launchpadWorkflow?WorkflowId=` + workflowType;
  }

  return (
    <S.Wrapper>
      <ClickAwayListener onClickAway={handleClose}>
        <S.ModalWrapper>
          <S.ModalHeader>End-User License Agreement {title}</S.ModalHeader>
          <S.Description>
            Your subscription to Energy Data Insights on AWS for the OSDU® Data Platform - Ecosystem
            Launchpad is made available pursuant to the following trial services agreement:
            <h3>TRIAL SERVICES AGREEMENT</h3>
            <h4>AWS Energy Data Insights for the OSDU® Data Platform - Ecosystem Launchpad</h4>
            Hitachi Vantara LLC, located at 2535 Augustine Drive, Santa Clara, CA 95054 (“Us, We,
            Our”) and you (“You or Your”) agree that this Trial Agreement (“Agreement”) governs Your
            access to, and use of, the trial service offering, including but not limited to
            evaluation environments and all software, data and content made available therein
            (“Services”) for the purpose of Your internal testing of the service offering in
            connection with doing business with Us (the “Purpose”). BY DOWNLOADING, INSTALLING,
            COPYING, ACCESSING, OR USING THE SERVICES, OR BY CHOOSING THE “I ACCEPT“ OPTION LOCATED
            ON OR ADJACENT TO THE SCREEN WHERE THIS AGREEMENT MAY BE DISPLAYED, YOU AGREE TO THE
            AGREEMENT. IF YOU ARE ACCEPTING THESE TERMS ON BEHALF OF ANOTHER PERSON, COMPANY OR
            OTHER LEGAL ENTITY, YOU REPRESENT AND WARRANT THAT YOU HAVE FULL AUTHORITY TO BIND THAT
            PERSON, COMPANY, OR LEGAL ENTITY TO THIS AGREEMENT. IF YOU DO NOT AGREE TO THE
            AGREEMENT, DO NOT CLICK AN ACCEPT BUTTON OR DOWNLOAD, INSTALL, COPY, ACCESS, OR USE THE
            SERVICES.
            <ol>
              <li>
                Subject to the payment of any applicable fees, We allow You to use the Services or
                Enabling Software exclusively for the Purpose during the Service Period. The Service
                Period begins on the date You agree to the terms of the Agreement and ends on the
                earliest of the date specified by Us or the date on which Your access to the Service
                is disabled by Us (“Service Period”). We may terminate this Agreement at any time by
                providing You written notice. You may terminate this Agreement at any time by
                terminating your subscription to the Services in the Cloud Marketplace. Your use of
                the Services is contingent upon Your compliance with the terms and conditions of
                this Agreement.
              </li>
              <li>
                You agree not to transfer to another person or entity, including, but not limited
                to, Your customers or potential customers, the right to use (including any security
                or account information) any part of the Services.
              </li>
              <li>
                You accept the Services or Enabling Software “AS-IS” WITHOUT ANY WARRANTY OR
                CONDITION, EXPRESS, IMPLIED OR STATUTORY OF ANY KIND. To the full extent permitted
                by law, We expressly disclaim any implied or statutory warranties, including that
                the Services or Enabling Software will operate uninterrupted or error-free, or will
                be free of computer viruses or similar contamination or destructive features. We are
                not liable for any direct, indirect, special, incidental, consequential or other
                damages, or for any data disclosed or compromised, or any loss of, or destruction to
                data, however caused, in connection with Your use of the Services or Enabling
                Software, whether for breach of contract, negligence or otherwise, and whether or
                not You have been advised of the possibility of such damages. You agree and
                acknowledge that the Services a) may not have been tested or certified to meet
                security requirements, b) may not provide standard security features like encryption
                and c) may not be secure against cyber attacks or other data breaches.
              </li>
              <li>
                This Agreement is for the Services or Enabling Software, if any. You are responsible
                for any computer and storage infrastructure, hardware, software, network cabling or
                appliances required at Your site for internet connection to the Services. No forms
                of connectivity or onramps are provided by Us. “Enabling Software” is any Material
                provided to You by Us or a third party as part of the Services to facilitate access
                to or use of the Services. “Materials” are software, instructions and data, images,
                graphics, visual content, related licensed materials, or license keys. If You
                download or install Enabling Software, You agree to use such Enabling Software only
                for You to access or use the Services for the Purpose during the Service Period
                only. If Enabling Software is presented with a separate license agreement, such
                separate agreement will govern its use. You agree to such terms by accepting this
                Agreement or downloading, installing or using the Enabling Software.
              </li>
              <li>
                As part of the Services, You permit Us, within the hosted account that We provide to
                You, to: (i) install the Services; (ii) establish Your access to the Services; (iii)
                if available, to provide you with support services that you request from Us; and
                (iv) to remove Your access to the Services at the end of the Service Period.
              </li>
              <li>
                You are responsible for all data that You provide in connection with the Services,
                including without limitation, all operational and technical measures to safeguard
                the security and integrity of that data. You also agree not to use the Services in
                any live production environment or for commercial purposes. You agree not to store
                any data that requires confidential or secure treatment, including but not limited
                to sensitive, regulatory or personal data. We are not responsible for any loss or
                compromise of data or breach of data security arising from the Services (including
                any support provided by Us to You as part of the Services). If You do choose to
                input personal data into, or make personal data accessible to, the Service, You do
                so at Your own risk and We assume no liability for the protection of personal data
                or for compliance with applicable data protection laws. Without prejudice to the
                provisions contained in the Agreement, You agree that the provision of Services that
                are the object of the Agreement, do not entail the processing of personal data on
                Your behalf by Us. Notwithstanding the foregoing, if We are required to process
                personal data on Your behalf as a Data Processor, as defined in the General Data
                Protection Regulation EU 2016/679, You must notify Us at privacy@hitachivantara.com
                and the parties will enter into the corresponding addendum, “Hitachi Vantara
                Customer Data Protection Addendum” available at:{' '}
                <a
                  href={
                    'https://www.47lining.com/paas/docs/adopt/adopt-docs-audience@latest/adopt-docs/commercial-terms/data-protection-addendum/'
                  }
                >
                  https://www.47lining.com/paas/docs/adopt/adopt-docs-audience@latest/adopt-docs/commercial-terms/data-protection-addendum/
                </a>
                . You agree that We and our affiliates may use information derived from or generated
                by the Services to provide, maintain, protect, support, and improve the service
                offerings and to develop new products and services, to the extent permitted by
                applicable law. You acknowledge that We do not control the transfer of data over
                telecommunication facilities, including the internet, and in the public internet
                environment, We cannot commit to particular confidentiality obligations.
              </li>
              <li>
                You are solely responsible for the development, operation, maintenance, backup, and
                use of all the content that You run on the Services, cause to interface with the
                Services, provide in connection with the Services, upload to the Services under Your
                account or otherwise transfer, process, use, or store in connection with Your
                account, including software (including machine images), data, text, audio, video,
                images or any other content (“Content”). You are responsible for the procedures and
                controls regarding access, security, encryption, use, transmission and backup and
                recovery of Content. You are also responsible for ensuring that your Content is
                backed up at regular intervals to protect against the possibility of loss in this
                testing environment. YOU AGREE TO INDEMNIFY, DEFEND AND HOLD HARMLESS US, OUR
                AFFILIATES, SUPPLIERS AND LICENSORS AGAINST ANY AND ALL LIABILITIES, CLAIMS,
                DAMAGES, COSTS OR OTHER EXPENSES (INCLUDING BUT NOT LIMITED TO ATTORNEYS’ FEES,
                EXPENSES, AND EXPERT WITNESS FEES) THAT ARISE DIRECTLY OR INDIRECTLY OUT OF OR FROM
                (i) YOUR BREACH OF THIS TRIAL AGREEMENT, OR (ii) YOUR USE OF THE SERVICES.
              </li>
              <li>
                As between You and Us: (i) We and our affiliates and licensors own all right, title
                and interest, including all intellectual property rights, in the Services or
                Enabling Software and all infrastructure and technology used in the supply of the
                Services and You obtain no rights in such items, other than as expressly provided
                for in this Agreement, and (ii) You and Your licensors own all right, title and
                interest, including all intellectual property rights, in the Content. However, You
                consent to (a) Our use of such Content to provide the Services to You or to comply
                with any request of a governmental regulatory body (including subpoenas and court
                orders), (b) the processing of your Content in and the transfer of your Content into
                any country in which Hitachi or its affiliates or subcontractors maintain facilities
                (including the United States). During and after the Service Period, You will not
                assert, nor will You authorize, assist, or encourage any third party to assert,
                against Us or any of Our affiliates, customers, vendors, business partners, or
                licensors, any patent infringement or other intellectual property infringement claim
                regarding the Services You have used.
              </li>
              <li>
                You have no right to use any trademark or brand name of Ours or of our affiliates,
                pursuant to this Agreement.
              </li>
              <li>
                You represent and warrant to us that: (a) You or Your licensors own all right,
                title, and interest in and to the Content; (b) You have all rights in such Content
                necessary to grant the rights contemplated by this Agreement; (c) You are
                responsible for all activities that occur while utilizing the Services; (d) none of
                the Content, or Your use of the Content, will violate any laws or regulations, this
                Agreement or the Hitachi Acceptable Use Policy (available at
                https://www.hitachivantara.com/en-us/company/legal.html); and (e) that information
                that you provide to Us during the fulfillment process to provide you with the
                Services is accurate and appropriate.
              </li>
              <li>
                “Feedback” shall be defined as all input, suggestions and other evaluative
                information that You derive from the Services. By providing Feedback, You grant to
                Hitachi under all applicable intellectual property rights owned or controlled by You
                a non-exclusive, non-transferable, worldwide, perpetual, irrevocable, royalty-free
                license to use, disclose, copy, publish, license, modify, sublicense or otherwise
                distribute and exploit Feedback You provide for the purpose of developing and
                promoting any product, software, service or technology. You warrant to the best of
                Your knowledge that you have the right to provide the Feedback, and if an individual
                is providing Feedback on Your behalf, You warrant that the individual has the right
                to provide Feedback on Your behalf. You acknowledge that We are not required to
                incorporate Your Feedback into any version of the Services or any product, but that
                We may elect to do so.
              </li>
              <li>
                Restrictions on Use. You must not: (i) create a lien on, sell, assign, rent,
                sub-license, lease, distribute, create derivative works of, or otherwise transfer
                the Services or Enabling Software to any third party; (ii) copy or reproduce the
                Services, Enabling Software in any manner without the prior written consent of
                Hitachi; (iii) disclose the Services or Enabling Software, to anyone, except to Your
                employees who require access to carry out the purpose of this Agreement; (iv)
                modify, disassemble, decompile, reverse engineer or translate the Services or
                Enabling Software (except to the extent these restrictions are prohibited by
                applicable law), (v) use the Services or Enabling Software in any competitive manner
                against Us, or (vi) allow any person or entity to commit any of the actions
                described in this section. You will take appropriate action, with respect to Your
                personnel permitted access to the Services and Enabling Software to ensure that all
                Your obligations are satisfied.
              </li>
              <li>
                If the Services links to third-party websites (“External Sites”), these links are
                provided solely as a convenience to You and not as an endorsement by Us of the
                content on such External Sites. The content of such External Sites is developed and
                provided by others. You should contact the site administrator or webmaster for those
                External Sites if you have any concerns regarding such links or any content located
                on such External Sites. We are not responsible for the content of any linked
                External Sites and do not make any representations regarding the content or accuracy
                of materials on such External Sites. You should take precautions when downloading
                files form all websites to protect Your computer from viruses and other destructions
                programs. If you access any External Site, You do so at Your own risk.
              </li>
              <li>
                If support services are available and at our option, We will make reasonable efforts
                to provide such support services to You, provided that You provide Us the necessary
                access to provide such support services.
              </li>
              <li>
                Each party to this Agreement agrees to comply fully with all relevant laws and
                regulations of the United States and other countries, including export control laws.
                In various countries, the laws and regulations governing the export of computer
                products and technology may prohibit the use, sale or re-export of such products or
                technology. You must make sure that You are aware of those laws and, if You know (or
                have reason to know) that such products and technology are intended for use in
                connection with the design, development, production, stock piling or use of nuclear,
                chemical or biological weapons or missiles and, in some countries (e.g. China), for
                certain conventional military end-uses, You must not allow or facilitate the export
                of those products or technology.
              </li>
              <li>
                At the end of the Service Period, You agree to promptly discontinue use of the
                Services and destroy any copies of the Enabling Software within Your possession. We
                may, in Our sole discretion, remove the Services and Your Content at the end of the
                Service Period. We may limit, suspend or terminate Your access to any portion of
                Services for any reason in Our sole discretion, including enforcing the Services
                usage limitations or protecting Our services or systems. The Services may change
                without notice and, if released commercially, may differ substantially upon
                commercial release. You agree that We may change this Agreement at any time without
                prior notice. The revised terms and conditions will become effective at the time of
                posting. Any use of the Services after such date shall constitute Your acceptance of
                such revised terms and conditions.
              </li>
              <li>
                Hitachi is a registered trademark of Hitachi, Ltd. In the United States and other
                countries. All other trademarks, service marks, and company names are properties of
                their respective owners. © Hitachi Vantara LLC 2020 All Rights Reserved.
              </li>
              <li>
                This Agreement is subject to the laws of the state of New York and the courts in New
                York City will have exclusive jurisdiction. If any provision of this Agreement is
                found to be invalid by any court having competent jurisdiction, the invalidity of
                such provision shall not affect the validity of the remaining provisions of the
                Agreement, which shall remain in full force and effect. Failure of Hitachi to act on
                or enforce any provision of this Agreement shall not be construed as a waiver of
                that provision or any other provision in the Agreement. No waiver shall be effective
                against Hitachi unless made in writing, and no such waiver shall be construed as a
                waiver in any other subsequent instance.
              </li>
            </ol>
          </S.Description>
          {/* <S.Row>
            <S.DownloadTos href={ToS} download={`ToS.txt`}>
              Download ToS
            </S.DownloadTos>
          </S.Row> */}
          <S.Row>
            <S.CancelButton disabled={isLoading} onClick={handleClose}>
              Cancel
            </S.CancelButton>
            <S.AcceptToS disabled={isLoading} onClick={handleAccept}>
              {isLoading ? <CircularProgress size={18} /> : 'Accept'}
            </S.AcceptToS>
          </S.Row>
        </S.ModalWrapper>
      </ClickAwayListener>
    </S.Wrapper>
  );
}
