import styled from 'MUI/theme/styled';

export const Wrapper = styled('div')(
  ({
    theme: {
      palette: { grey2, white },
    },
  }) => ({
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    backgroundColor: white,
    WebkitFontSmoothing: 'antialiased',
    '@media(min-height: 800px) and (min-width: 1024px)': {
      height: '100vh',
    },
    '#gatsby-focus-wrapper': {
      flexGrow: 1,
      margin:　'auto',
      boxSizing: 'border-box',
      display: 'flex',
      flexDirection: 'column',
      backgroundColor: white,
      width: '100%',
    },
  })
);
