import { IWorkflow } from 'Components/Workflow';
import { createContext } from 'react';
import { Dispatch, SetStateAction } from 'react';

export enum MODAL_TYPE {
  ABOUT = 'ABOUT',
  TOS = 'TOS',
}

export interface IModal {
  modalType: MODAL_TYPE;
  workflowData: IWorkflow;
}

export interface IContext {
  selectedModal: IModal | undefined;
  setSelectedModal: Dispatch<SetStateAction<IModal | undefined>>;
}

export default createContext<IContext>({
  selectedModal: undefined,
  setSelectedModal: () => {},
});
