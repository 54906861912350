module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-offline/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"OSDU in Action Ecosystem Launchpad","shortName":"Ecosystem Launchpad","description":"OSDU in Action Ecosystem Launchpad provides a list workflows where user can experience OSDU Data Platform and integrated partner application that enhances the experience and provides new features.","start_url":"/","background_color":"#F1F4F6","theme_color":"#232F3F","display":"standalone","icon":"static/images/favicon.svg","crossOrigin":"use-credentials","legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","include_favicon":true,"cacheDigest":"259e1339a21cf9a16881f722296cb704"},
    },{
      plugin: require('../node_modules/gatsby-plugin-web-font-loader/gatsby-browser.js'),
      options: {"plugins":[],"custom":{"families":["AmazonEmber"],"urls":["/fonts/fonts.css"]}},
    },{
      plugin: require('../gatsby-browser.tsx'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby/dist/internal-plugins/partytown/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
