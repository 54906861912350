import { StaticImage } from 'gatsby-plugin-image';
import * as S from './TopBar.style';

export default function TopBar() {
  return (
    <S.Wrapper>
      <S.Container>
        <S.LogoLink to="/">
          <StaticImage
            height={42}
            objectFit={'contain'}
            src={'../../Images/Amazon_Web_Services_Logo.svg'}
            alt={'Amazon Web Services'}
            layout="constrained"
          />
          
        </S.LogoLink>
        <S.Heading>
        Energy Data Insights on AWS
        </S.Heading>
      </S.Container>
    </S.Wrapper>
  );
}
