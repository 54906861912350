import { StaticImage } from 'gatsby-plugin-image';
import * as S from './Partners.style';

export default function Partners() {
  return (
    <S.Partners>
      <S.PartnersHeader>Partners</S.PartnersHeader>
      <S.PartnersContainer>
        <S.StyledAnchor
          target={'_blank'}
          href={'https://pages.awscloud.com/GLOBAL-field-OE-47lining-odsu-2022-reg.html'}
        >
          <StaticImage
            width={64}
            id="47Lining"
            src={'../../Images/47Logo.png'}
            alt={'47Lining Logo'}
            layout="constrained"
          />
        </S.StyledAnchor>
        <S.StyledAnchor
          target={'_blank'}
          href={'https://pages.awscloud.com/GLOBAL-aware-ABM-osdu-int-2021-learn.html'}
        >
          <StaticImage
            id="INT Logo"
            src={'../../Images/int-logo-with-name.png'}
            alt={'INT Logo'}
            width={63}
            layout="constrained"
          />
        </S.StyledAnchor>
        <S.StyledAnchor target={'_blank'} href={'https://www.petrabytes.com/'}>
          <StaticImage
            id="Petrabytes Logo"
            src={'../../Images/petrabytes-logo-with-name.png'}
            alt={'Petrabytes Logo'}
            width={119}
            objectFit="contain"
            layout="constrained"
          />
        </S.StyledAnchor>
        <S.StyledAnchor
          target={'_blank'}
          href={'https://pages.awscloud.com/GLOBAL-aware-ABM-osdu-bluware-2021-learn.html'}
        >
          <StaticImage
            id="Bluware Logo"
            src={'../../Images/bluware-logo-with-name.png'}
            alt={'Bluware Logo'}
            width={38}
            objectFit="contain"
            layout="constrained"
          />
        </S.StyledAnchor>
        <S.StyledAnchor target={'_blank'} href={'https://www.questlabs.in/'}>
          <StaticImage
            id="Bluware Logo"
            src={'../../Images/questlabs-logo.png'}
            alt={'Bluware Logo'}
            width={73}
            objectFit="contain"
            layout="constrained"
          />
        </S.StyledAnchor>
        <S.StyledAnchor target={'_blank'} href={'https://www.ikonscience.com/'}>
          <StaticImage
            id="Ikon Science Logo"
            src={'../../Images/ikon-science.png'}
            alt={'Ikon Science Logo'}
            width={71}
            objectFit="contain"
            layout="constrained"
          />
        </S.StyledAnchor>
        <S.StyledAnchor
          target={'_blank'}
          href={'https://www.petrosys.com.au/products/interica-oneview/'}
        >
          <StaticImage
            id="Interica Logo"
            src={'../../Images/interica_logo.png'}
            alt={'Ikon Science Logo'}
            width={198}
            objectFit="contain"
            layout="constrained"
          />
        </S.StyledAnchor>
      </S.PartnersContainer>
    </S.Partners>
  );
}
