import styled from 'MUI/theme/styled';

export const Wrapper = styled('div')(
  ({
    theme: {
      palette: { black },
      zIndex: { modal },
    },
  }) => ({
    position: 'fixed',
    top: 0,
    left: 0,
    width: '100vw',
    height: '100vh',
    backgroundColor: `${black}a5`,
    zIndex: modal,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    padding: '16px',
    boxSizing: 'border-box',
  })
);

export const ModalWrapper = styled('div')(
  ({
    theme: {
      palette: { white },
    },
  }) => ({
    padding: '16px',
    maxWidth: '800px',
    width: '100%',
    maxHeight: '90wh',
    backgroundColor: white,
    borderRadius: '4px',
  })
);

export const ModalHeader = styled('h2')(
  ({
    theme: {
      typography: { fontFamily },
    },
  }) => ({
    fontWeight: 600,
    width: '100%',
    marginTop: '0',
    fontFamily,
  })
);

export const DescriptionContainer = styled('div')(
  ({
    theme: {
      palette: { grey4 },
      typography: { fontFamily, body2, ...rest },
    },
  }) => ({
    p: {
      ...body2,
    },
    ul: {
      ...body2,
    },
    ...rest,
    fontFamily,
    lineHeight: '120%',
    overflow: 'auto',
    maxHeight: '75vh',
    height: 'fit-content',
    transition: 'height 300ms',
    '::-webkit-scrollbar': {
      width: '5px',
      height: '5px',
    },
    scrollbarWidth: 'thin',
    scrollbarHeight: 'thin',
    scrollbarColor: `${grey4} transparent`,
    '::-webkit-scrollbar-track-piece': {
      backgroundColor: 'transparent',
    },
    '::-webkit-scrollbar-thumb': {
      borderRadius: '5px',
      backgroundColor: grey4,
    },
  })
);

export const Row = styled('div')({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'end',
  margin: '0 0 16px',
});

export const ToSButton = styled('button')({
  background: `linear-gradient(180deg,#f7dfa5 0,#f0c14b)`,
  cursor: 'pointer',
  borderRadius: '2x',
  padding: '3px 18px',
  fontSize: '12px',
  border: 'none',
  width: '140px',
  minHeight: '26px',
  textDecoration: 'none',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  boxShadow: 'inset 0 1px 0 hsl(0deg 0% 100% / 40%)',
});

export const AcceptToS = styled('button')(
  ({
    theme: {
      palette: {
        secondary: { main, dark },
      },
    },
  }) => ({
    background: `linear-gradient(180deg,${main} 0, ${dark})`,
    cursor: 'pointer',
    padding: '3px 18px',
    fontSize: '14px',
    border: 'none',
    width: '140px',
    minHeight: '26px',
    textDecoration: 'none',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    boxShadow: 'inset 0 1px 0 hsl(0deg 0% 100% / 40%)',
    margin: '0 0 0 auto',
    fontWeight: '600',
    height: '34px',
    borderRadius: '16px',
  })
);

export const DownloadTos = styled('a')({
  background: `linear-gradient(180deg,#f7dfa5 0,#f0c14b)`,
  cursor: 'pointer',
  borderRadius: '2x',
  padding: '0',
  fontSize: '12px',
  border: 'none',
  width: '140px',
  minHeight: '26px',
  textDecoration: 'none',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  boxShadow: 'inset 0 1px 0 hsl(0deg 0% 100% / 40%)',
  margin: '0 8px 0 0',
  color: 'black',
});
