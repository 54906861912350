import { ReactNode, useMemo, useState } from 'react';
import Context, { IModal, IContext } from './Context';

export default function ContetProvider({ children }: { children: ReactNode }) {
  const [selectedModal, setSelectedModal] = useState<IModal>();

  const contextValue: IContext = useMemo(
    () => ({
      selectedModal,
      setSelectedModal,
    }),
    [selectedModal]
  );

  return <Context.Provider value={contextValue}>{children}</Context.Provider>;
}
